<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                    <div class="tabs-bottom-line"></div>
                    <b-tab active>
                        <template #title><i class="ri-message-line top-plus-2 mr-2"></i>{{$t('student') + ' & ' + $t('thesis_and_project')}}</template>
                        <ValidationObserver ref="updateForm">
                            <div class="row">
                                <div class="col-4 col-xl-3">
                                    <b-form-group :label="$t('name')">
                                        {{formData.student ? formData.student.name : '-'}}
                                    </b-form-group>
                                </div>
                                <div class="col-8 col-xl-9">
                                    <b-form-group :label="$t('surname')">
                                        {{formData.student ? formData.student.surname : '-'}}
                                    </b-form-group>
                                </div>
                                <div class="col-4 col-xl-3">
                                    <b-form-group :label="$t('program')">
                                        {{formData.student ? formData.student.program_name : '-'}}
                                    </b-form-group>
                                </div>
                                <div class="col-8 col-xl-9">
                                    <b-form-group :label="$t('student_number')">
                                        {{formData.student ? formData.student.student_number : '-'}}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-xl-12">
                                    <hr>
                                    <h6>{{$t('thesis_and_project_info')}}</h6>
                                </div>

                                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                    <ValidationProvider name="title" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('title')">
                                            <b-form-input
                                                v-model="updateForm.title"
                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                    <ValidationProvider name="advisor" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="formData.type == 'thesis' ? $t('advisor_of_thesis') : $t('advisor_of_project')">
                                            <advisors-selectbox
                                                v-model="updateForm.advisor"
                                                :type="formData.type"
                                                :validateError="errors[0]">
                                            </advisors-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <ValidationProvider name="summary" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('summary')">
                                            <b-form-textarea no-resize rows="4" class="h-80 h-auto-sm"
                                                             v-model="updateForm.summary"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-xl-4">
                                    <b-form-group :label="$t('application_date')">
                                        {{formData ? formData.created_at : '-'}}
                                    </b-form-group>
                                </div>
                            </div>
                        </ValidationObserver>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-user-3-line top-plus-2 mr-2"></i>{{$t('advisor')}}</template>
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <b-form-group :label="$t('advisor')">
                                    {{formData.advisor ? formData.advisor.name + ' ' + formData.advisor.surname : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('thesis_advisory')">
                                    {{formData ? formData.advisor_approved_thesis_count + '/14' : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('project_advisory')">
                                    {{formData ? formData.advisor_approved_project_count + '/16' : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('total_advisory')">
                                    {{formData ? formData.advisor_approved_project_count + formData.advisor_approved_thesis_count + '/30' : '-'}}
                                </b-form-group>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-user-6-line top-plus-2 mr-2"></i>{{$t('thesis_students')}}</template>
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <b-table striped hover :items="thesisStudents" :fields="thesisStudentFields"></b-table>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-between">
                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ThesisAndProjectService from "@/services/ThesisAndProjectService";
import moment from "moment";
import AdvisorsSelectbox from "@/components/interactive-fields/AdvisorsSelectbox";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        AdvisorsSelectbox
    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            tabIndex: 1,
            formData: {},
            status: null,
            reason: null,
            thesisStudents: [],
            thesisStudentFields: [
                {
                    key: 'count_number',
                    label: this.$t('count_number')
                },
                {
                    key: 'student',
                    label: this.$t('student')
                },
                {
                    key: 'period',
                    label: this.$t('period')
                },
                {
                    key: 'approved_date',
                    label: this.$t('approved_date')
                },
                {
                    key: 'advisor',
                    label: this.$t('advisor')
                },
                {
                    key: 'approval_status',
                    label: this.$t('approval_status')
                },
                {
                    key: 'title',
                    label: this.$t('title')
                },
                {
                    key: 'program',
                    label: this.$t('program')
                }
            ],

            updateForm: {
                title: null,
                summary: null,
                advisor: null,
                type: null
            }

        }
    },
    metaInfo() {
        return {
            title: this.$t("thesis_and_projects_admin_title")
        }
    },
    methods: {
        getData(Id) {
            ThesisAndProjectService.show(Id).then(response => {
                this.formData = response.data.data
                this.formData.created_at = moment(this.formData.created_at).format('DD.MM.YYYY')
                this.updateForm.title = this.formData.title
                this.updateForm.summary = this.formData.summary
                this.updateForm.advisor = this.formData.advisor_id

                let nmb = 1
                this.thesisStudents = []
                this.formData.advisor_approved_students.forEach((item) => {
                    this.thesisStudents.push({
                        count_number: nmb,
                        student: item.name + ' ' + item.surname,
                        period: item.academic_year + ' ' + item.semester_name,
                        approved_date: moment(item.created_at).format('DD.MM.YYYY'),
                        advisor: this.formData.advisor.name + ' ' + this.formData.advisor.surname,
                        approval_status: this.$t('approved'),
                        title: item.title,
                        program: item.program_name
                    })
                    nmb++
                })
            })
        },



        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = {
                    type: this.formData.type,
                    title: this.updateForm.title,
                    summary: this.updateForm.summary,
                    advisor_id: this.updateForm.advisor
                }
                ThesisAndProjectService.update(this.id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.title) {
                            this.$refs.updateForm.errors.title.push(e.data.errors.title[0]);
                        }
                        if (e.data.errors.summary) {
                            this.$refs.updateForm.errors.summary.push(e.data.errors.summary[0]);
                        }
                        if (e.data.errors.advisor_id) {
                            this.$refs.updateForm.errors.advisor.push(e.data.errors.advisor_id[0]);
                        }
                    }
                })
            }
        }
    },

    created() {

    },

    watch: {
        id: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>
